import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Spinner } from 'reactstrap'
import Carousel from '../../components/atomics/Carousel'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import nl2br from 'react-nl2br'
import Series from '../../components/molecules/Series'
import { actions as seriesActions } from '../../modules/series'
import { isEmptyObject } from '../../util/common'
import { media } from '../../components/atomics/Media'
import { IMG_URL, HP_URL, HP_EN_URL } from '../../constants/api-config'
import { actions as locationActions } from '../../modules/location'
import { LoadingWrapper, Loading } from '../../components/molecules/Loading'

class SeriesCatalog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      class1_cd: null,
      class2_cd: null,
    }
  }

  componentDidMount() {
    this.loadData()
  }
  componentDidUpdate() {
    this.loadData()
  }

  loadData = () => {
    // 商品情報読み込み
    const { class1_cd = null, class2_cd = null } = this.props.match.params

    if (class1_cd === this.state.class1_cd && class2_cd === this.state.class2_cd) {
      return
    }

    // パスだけ変えたときの再検索
    this.setState({ class1_cd })
    this.setState({ class2_cd })

    // 戻ってきたら検索しない
    // MEMO: HistoryBackで戻ったら history.action == POP になる。
    //　　　　一覧へで戻ったら isReturnItems == true で戻している。
    if (this.props.isReturnItems) {
      this.props.locationSet('isReturnItems', false)
    }
    if (!isEmptyObject(this.props.searchedSeries)) {
      if (this.props.isReturnItems) {
        // スクロール位置を戻す
        // @@router/LOCATION_CHANGE イベントでスクロール位置を変えても、上手く行かない。
        window.scrollTo(0, this.props.referenceScroll)
        return
      } else if (this.props.history.action === 'POP') {
        // 検索時のパラメータがパスと同じなら再検索しない
        if (this.props.searchedParams?.class1_cd === class1_cd && this.props.searchedParams?.class2_cd === class2_cd) {
          return
        }
      }
    }

    this.props.searchSeries(class1_cd, class2_cd)
  }

  render() {
    const {
      productClass1,
      productClass2,
      searchedSeries,
      isSearching,
      hasMore,
      set,
      link,
      replace,
      searchSeriesNext,
      isWeb,
      i18n,
      t,
    } = this.props

    if (isEmptyObject(productClass1)) {
      return <div></div>
    }

    // トップ画面に遷移
    const onClickHome = () => {
      if (isWeb) {
        if (i18n.language === 'en') {
          window.location.href = HP_EN_URL
        } else {
          window.location.href = HP_URL
        }
      } else {
        link('/')
      }
    }

    const searching = () => {
      return (
        <LoadingWrapper style={{ background: '#fff' }}>
          <Loading>
            <Spinner olor="secondary" size={'sm'} style={{ width: '5rem', height: '5rem' }} />
          </Loading>
        </LoadingWrapper>
      )
    }

    const onClickConcept = () => {
      window.location.href =
        i18n.language === 'en'
          ? productClass1.web_consept_url.replace(HP_URL, `${HP_EN_URL}`)
          : productClass1.web_consept_url
    }

    let catalogImage = {
      cd: productClass1.class1_cd,
      numbers: productClass1.web_catalog1_image_numbers || [],
    }
    if (!isEmptyObject(productClass2)) {
      catalogImage = {
        cd: `${productClass1.class1_cd}_${productClass2.class2_cd}`,
        numbers: productClass2.web_catalog1_image_numbers || [],
      }
    }

    return (
      <Content>
        <div className="list-sp">
          <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            <BreadcrumbItem>{productClass1.web_display_name}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="catalog-image list-pc">
          {catalogImage.numbers.length >= 1 && (
            <Carousel
              items={catalogImage.numbers}
              arrows={false}
              renderItem={(item) => <img src={`${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}.png`} />}
            ></Carousel>
          )}
        </div>
        <div className="catalog-image list-sp">
          {catalogImage.numbers.length >= 1 && (
            <Carousel
              items={catalogImage.numbers}
              arrows={false}
              renderItem={(item) => (
                <img
                  src={`${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}_sp.png`}
                  onError={(e) => {
                    if (e.target.src != `${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}.png`) {
                      e.target.src = `${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}.png`
                    }
                  }}
                />
              )}
            ></Carousel>
          )}
        </div>
        <div className="class2-header">
          <Container fluid={true} className={catalogImage.numbers.length <= 1 ? 'class2-tab' : 'class2-tabs'}>
            <Row>
              {productClass1.product_class2s.map((class2) => (
                <Col
                  key={class2.id}
                  className={`${class2 === productClass2 && 'active'}`}
                  onClick={() => replace(`/series/${productClass1.class1_cd}/${class2.class2_cd}`)}
                >
                  {i18n.language === 'en' ? class2.web_eng_display_name : class2.web_display_name}
                </Col>
              ))}
            </Row>
          </Container>
          <div className="concept">
            {!isEmptyObject(productClass1.web_consept_url) && (
              <a onClick={() => onClickConcept()}>{productClass1.web_display_name} CONCEPT</a>
            )}
          </div>
          {!isEmptyObject(productClass2) && (
            <div className="frame">
              <div className="frame-name">
                {i18n.language === 'en' ? productClass2.web_eng_display_name : productClass2.web_display_name}
              </div>
              <div className="frame-description">
                {i18n.language === 'en'
                  ? nl2br(productClass2.web_eng_discription)
                  : nl2br(productClass2.web_discription)}
              </div>
            </div>
          )}
          {isEmptyObject(productClass2) && productClass1 && (
            <div className="series">
              <div className="series-name">
                {i18n.language === 'en' ? productClass1.web_eng_display_name : productClass1.web_display_name}
              </div>
              <div className="series-description">
                {nl2br(i18n.language === 'en' ? productClass1.web_eng_discription : productClass1.web_discription)}
              </div>
            </div>
          )}
        </div>
        <div className="result">
          {searchedSeries.length === 0 && isSearching === false && (
            <div className="not-found">{t('該当の商品はありませんでした。')}</div>
          )}
          <Container fluid={true} className="series">
            <Row xs={1} md={2}>
              {searchedSeries.map((series) => (
                <Col key={series.id}>
                  <Series
                    key={series.id}
                    series={series}
                    onClick={(e) => link('/items/')}
                    pageName={productClass1.web_display_name}
                  />
                </Col>
              ))}
            </Row>
          </Container>
          {isSearching && searching()}
          {hasMore && (
            <div
              className="more"
              onClick={(e) => {
                e.target.blur()
                searchSeriesNext(
                  productClass1.class1_cd,
                  !isEmptyObject(productClass2) ? productClass2.class2_cd : null
                )
              }}
            >
              VIEW MORE
            </div>
          )}
        </div>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            <BreadcrumbItem>{productClass1.web_display_name}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { class1_cd = null, class2_cd = null } = props.match.params

  const productClass1 = state.master.productClasses.find((productClass1) => productClass1.class1_cd === class1_cd) || {}
  const productClass2 = !isEmptyObject(productClass1)
    ? productClass1.product_class2s.find(
        (class2) => class2.web_catalog1_display_flag === '1' && class2.class2_cd === class2_cd
      )
    : {}
  return {
    productClass1,
    productClass2,
    searchedSeries: state.series.searchedSeries || [],
    searchedParams: state.series.searchedParams || {},
    isSearching: state.series.isSearching || false,
    hasMore:
      state.series.searchedMeta.current_page !== 0 &&
      state.series.searchedMeta.current_page < state.series.searchedMeta.last_page,
    isWeb: state.location.isWeb,

    isReturnItems: state.location.isReturnItems,
    referenceScroll: state.location.referenceScroll,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    replace: (url) => {
      dispatch(locationActions.replace(url))
    },
    searchSeries: (class1_cd, class2_cd) => {
      dispatch(seriesActions.searchSeries({ class1_cd, class2_cd }))
    },
    searchSeriesNext: (class1_cd, class2_cd) => {
      dispatch(seriesActions.searchSeriesNext({ class1_cd, class2_cd }))
    },
    locationSet: (key, value) => {
      dispatch(locationActions.set(key, value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SeriesCatalog)))

const Content = styled.div`
  .result {
    margin-bottom: 80px;
  }
  .catalog-image img {
    width: 100%;
  }

  .concept {
    margin: 30px;
    text-align: right;
    cursor: pointer;
    cursor: hand;
  }

  .concept:hover {
    text-decoration: underline;
  }

  .class2-header .series {
    margin-top: 20px;
    margin-bottom: 60px;

    .series-name {
      font-size: 22px;
      display: block;
    }
    .series-description {
      font-size: 13px;
      display: block;
    }
  }

  .frame {
    margin-top: 20px;
    margin-bottom: 60px;

    .frame-name {
      font-size: 22px;
      display: block;
    }
    .frame-description {
      font-size: 13px;
      display: block;
    }
  }

  .class2-tab {
    margin: 0;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;

    .col {
      padding: 10px 10px;
      text-align: center;
      cursor: pointer;
      color: #808080;
      font-size: 18px;

      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
    .col.active {
      width: 500px;
      border-bottom: solid 2px #808080;
      font-weight: bold;
    }
    .row {
      display: flex;
      flex-wrap: nowrap;
      word-break: keep-all;
      white-space: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .class2-tabs {
    margin: 0;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: 30px;

    .col {
      padding: 10px 10px;
      text-align: center;
      cursor: pointer;
      color: #808080;
      font-size: 18px;

      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
    .col.active {
      width: 500px;
      border-bottom: solid 2px #808080;
      font-weight: bold;
    }
    .row {
      display: flex;
      flex-wrap: nowrap;
      word-break: keep-all;
      white-space: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .series {
    .row {
      justify-content: flex-start;
    }

    .col {
      border: 1px solid #d6d6d6;
      overflow: hidden;
      border-left-width: 0;
      border-right-width: 0;
    }
    .col:nth-child(n + 2) {
      border-top-width: 0;
    }
  }

  .more {
    margin-bottom: 160px;
  }

  ${media.md_lg`
    .series .col {
      border: 0;

      flex-basis: calc((100% - 30px) / 2);
      margin-right: 30px;
      margin-bottom: 30px;
      height:420px;

      &:nth-child(2n) {
        margin-right: 0px;
      }
    }
  `}

  ${media.lg_xl`
    .series .col {
      border: 0;
      height:360px;

      flex-basis: calc((100% - 60px) / 3);
      margin-right: 30px;
      margin-bottom: 30px;

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }

    .class2-tab .col.active {
      width: 500px;
    }

    .class2-tabs .col.active {
      width: 500px;
    }
  `}

  ${media.lg`
    .concept {
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .class2-header .series {
      display: flex;
      align-items: center;

      .series-name {
        flex-shrink: 0;
      }
      .series-description {
        padding-left: 50px;
      }
    }

    .frame {
      display: flex;
      align-items: center;
  
      .frame-name {
        flex-shrink: 0;
      }
      .frame-description {
        padding-left: 50px;
      }
    }  
  `}

  ${media.xl`
    .series .col {
      border: 0;
      height:350px;

      flex-basis: calc((100% - 90px) / 4);
      margin-right: 30px;
      margin-bottom: 30px;

      &:nth-child(3n) {
        margin-right: 30px;
      }

      &:nth-child(4n) {
        margin-right: 0px;
      }
    }

    .class2-tab {
      overflow-x: hidden;
      
      .row {
        overflow-x: hidden;
      }
    }

    .class2-tab .col{
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.active {
        width: 500px;
      }
    }

    .class2-tabs {
      overflow-x: hidden;
      
      .row {
        overflow-x: hidden;
      }
    }

    .class2-tabs .col{
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.active {
        width: 500px;
      }
    }
  `}

  ${media.xs_sm`
    .name {
      text-align: left;
    }
    .result .series .col {
      height:417px;
      flex-basis: calc((100% - 40px));
      margin: 0 auto;
    }
    .concept {
      text-align: center;
    }
    .concept a {
      padding-bottom: 8px;
      border-bottom: 1px solid #000000;
      text-decoration: unset;
    }
    
    .class2-header .frame {
      margin: 0 20px 60px 20px;
      .frame-description {
        padding-left: 0;
      }
    }
    .class2-header .series {
      margin: 0 20px 60px 20px;
      .series-description {
        padding-left: 0;
      }
    }
  `}
  ${media.sm_md`
    .name {
      text-align: left;
    }
    .result .series .col {
      height:417px;
      flex-basis: calc((100% - 40px));
      margin: 0 auto;
    }
    .concept {
      text-align: center;
      margin-top: 40px;
    }
    .concept a {
      padding-bottom: 8px;
      border-bottom: 1px solid #000000;
      text-decoration: unset;
    }

    .class2-header .frame {
      margin: 0 20px 60px 20px;
      .frame-description {
        padding-left: 0;
      }
    }
    .class2-header .series {
      margin: 0 20px 60px 20px;
      .series-description {
        padding-left: 0;
      }
    }
  `}
`

import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, change, formValueSelector, reset } from 'redux-form'
import { IoMdHeartEmpty, IoMdHeart } from 'react-icons/io'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { actions as productDetailActions } from '../../modules/productDetail'
import { actions as favoritesActions } from '../../modules/favorites'
import { isEmpty, isEmptyObject } from '../../util/common'
import { actions as locationActions } from '../../modules/location'
import { actions as reservationAction } from '../../modules/reservation'
import { actions as authActions } from '../../modules/auth'
import { IMG_URL } from '../../constants/api-config'
import ProductImage from '../../components/molecules/ProductImage'
import ProductDetail from '../../components/molecules/ProductDetail'
import { media } from '../../components/atomics/Media'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'
import ClassNames from 'classnames'
import EcCartForm from '../../components/molecules/EcCartForm'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Thumbs } from 'swiper'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import classNames from 'classnames'

SwiperCore.use([Thumbs])

class Deatil extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainSlider: null,
      imageSlider: null,
      modelSlider: null,
    }
  }

  componentDidMount() {
    this.loadProduct()
    this.sliderReset()
  }
  componentDidUpdate() {
    this.loadProduct(true)
    this.sliderReset()
  }
  componentWillUnmount() {
    this.props.clearItemDetail()
  }

  loadProduct = (isUpdate = false) => {
    // EC表示のとき、EC非表示の商品が指定された
    // if (this.props.isEc && this.props.colorProduct && !Boolean(Number(this.props.colorProduct.ec_display_flag))) {
    //   this.props.link('/items/')
    //   return
    // }

    // 商品情報読み込み
    const { item_code } = this.props.match.params

    if (isEmptyObject(item_code)) {
      return
    }
    if (this.props.itemCode === item_code) {
      return
    }

    this.props.set('itemCode', item_code)
    this.props.getItemDetail(item_code, isUpdate)
  }

  sliderReset = () => {
    // HistoryBackされたときだけ、URLに合わせてスライドさせる
    if (this.props.history.action !== 'POP') {
      return
    }

    const { colorProduct, imagesProducts, product } = this.props

    // 初期表示のときは無効
    if (!this.state.modelSlider || !this.state.mainSlider) {
      return
    }

    // モデル画像のズレ補正
    if (product.series !== null) {
      const targetModelSlide = product?.series?.models?.findIndex((model) => model.id === product.model.id)
      if (targetModelSlide != this.state.modelSlider.realIndex) {
        this.state.modelSlider.slideToLoop(targetModelSlide)
      }
    }

    // メイン画像のズレ補正
    if (!isEmptyObject(colorProduct)) {
      const targetSlide = imagesProducts.findIndex((imagesProduct) => imagesProduct.product.id === colorProduct.id)
      if (targetSlide >= 0 && targetSlide != this.state.mainSlider.realIndex) {
        this.state.mainSlider.slideToLoop(targetSlide)
      }
    } else {
      if (imagesProducts[this.state.mainSlider.realIndex]?.type == 'product') {
        this.state.mainSlider.slideToLoop(0)
      }
    }
  }

  // ショップリスト　デバイスによってリンク変更
  linkShopList = () => {
    let url
    if (this.props.i18n.language === 'en') {
      url = `${HP_EN_URL}/shops/list/`
    } else {
      url = `${HP_URL}/shops/list/`
    }
    return window.location.assign(url)
  }

  render() {
    const {
      itemCode,
      product,
      colorProduct,
      colors,
      favorites,
      imageNumber,
      imagesProducts,
      toggleFavorite,
      flowStock,
      member,
      link,
      isWeb,
      isEc,
      t,
      i18n,
      otherTypesSizes,
      ecStocks,
      referencePath,
      referenceName,
    } = this.props

    const is_favorited = !!favorites.find((favorite) => {
      if (isEmptyObject(colorProduct)) {
        return favorite.id === product.id
      } else {
        return favorite.id === colorProduct.id
      }
    })

    const onToggleFavorite = () => {
      if (isEmptyObject(colorProduct)) {
        toggleFavorite(product)
      } else {
        toggleFavorite(colorProduct)
      }
    }

    const onClickHome = () => {
      if (isWeb) {
        if (i18n.language === 'en') {
          window.location.href = HP_EN_URL
        } else {
          window.location.href = HP_URL
        }
      } else {
        link('/')
      }
    }

    const onClickImageSlider = (index, imagesProduct) => {
      // 初期表示のときはキャンセル
      if (!this.state.modelSlider || !this.state.mainSlider) {
        return
      }

      this.props.set('imageNumber', imagesProduct.image_number)

      // 現在のURLに商品コードが含まれていれば何もしない
      const colorCode = imagesProduct.type === 'product' ? imagesProduct.product.product_cd : ''
      if (colorCode !== '') {
        const reg = new RegExp(`/items/${itemCode}/${colorCode}/?$`)
        if (this.props.location.pathname.match(reg)) {
          return
        }
      } else {
        const reg = new RegExp(`/items/${itemCode}/?$`)
        if (this.props.location.pathname.match(reg)) {
          return
        }
      }

      let url = ''
      if (imagesProduct.type === 'product') {
        url += `/items/${isEc ? 'ec/' : ''}${itemCode}/${imagesProduct.product.product_cd}`
      } else {
        url += `/items/${isEc ? 'ec/' : ''}${itemCode}`
      }
      link(url)
    }

    const onClickSeriesSlider = (index, model) => {
      // 初期表示のときはキャンセル
      if (!this.state.modelSlider || !this.state.mainSlider) {
        return
      }

      // 現在のURLにモデルコードが含まれていれば何もしない
      const itemCode = !isEmptyObject(model.model_products) ? model.model_products[0].product_cd : ''
      if (itemCode !== '') {
        const reg = new RegExp(`/items/${itemCode}/?$`)
        if (this.props.location.pathname.match(reg)) {
          return
        }
      }

      this.state.mainSlider.slideToLoop(0)
      if (imagesProducts.length > 0) {
        this.props.set('imageNumber', imagesProducts[0].image_number)
      }

      const url = `/items/${isEc ? 'ec/' : ''}${itemCode}`
      link(url)
    }

    const goBack = () => {
      if (!isEmpty(referencePath)) {
        const url = referencePath
        link({ pathname: url, state: { isReturnItems: true } })
      } else {
        // URLを直接入力してきた場合は商品検索へ遷移
        link('/items')
      }
    }

    if (isEmptyObject(product)) {
      return ''
    }

    // 飛び先 在庫保有店舗 おすすめ商品を取得
    const onFlowStockAction = (e) => {
      flowStock(colorProduct)
    }

    const redirectEc = () => {
      link(window.location.pathname.replace('/items/', '/items/ec/'))
    }

    // 最新コレクションフラグ取得
    // NOTE: グループ3が一致している商品の中から表示商品も含まれているためそこから値を取得
    const getLatestCollectionFlag = () => {
      let targetProduct = colors.find((item) => item.id == product.id)

      // NOTE: 念のため見つからない場合は0を返す
      return Boolean(targetProduct?.latest_collection_flag)
    }

    const NextArrow = ({ currentSlide, slideCount, ...props }) => (
      <div {...props}>
        <img src="/images/group_7415.svg" />
      </div>
    )

    const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
      <div {...props}>
        <img src="/images/group_7768.svg" />
      </div>
    )

    return (
      <Content>
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            {!isEmpty(referencePath) && !isEmpty(referenceName) && (
              <BreadcrumbItem>
                <a onClick={() => link(referencePath)}>{t(referenceName)}</a>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active>{t('商品詳細')}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        {colors.length > 0 && (
          <div className="detail-content">
            <div className="content-header">
              <div className="back" onClick={(e) => goBack()}>
                <div>
                  <img src="/images/Icon ionic-ios-return-left.svg" />
                </div>
                {t('一覧へ')}
              </div>
            </div>
            <div className="first-image">
              <Swiper
                thumbs={{
                  swiper: this.state.imageSlider,
                }}
                loop={true}
                initialSlide={
                  isEmptyObject(colorProduct)
                    ? 0
                    : imagesProducts.findIndex((imagesProduct) => imagesProduct.product.id === colorProduct.id)
                }
                onSwiper={(swiper) => {
                  this.setState({ mainSlider: swiper })
                }}
                onTransitionStart={(swiper) => {
                  onClickImageSlider(swiper.realIndex, imagesProducts[swiper.realIndex])
                }}
              >
                {imagesProducts.map((imagesProduct, index) => (
                  <SwiperSlide key={index}>
                    <div className="first-image-item">
                      <ProductImage
                        {...{ [imagesProduct.type]: imagesProduct.product }}
                        imageNo={imagesProduct.image_number.number}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {!isEmptyObject(colorProduct) && (
              <div className="color-info">
                <div className="color-code">
                  {!isEmpty(colorProduct.color && colorProduct.color.color_cd) && 'col.'}{' '}
                  {colorProduct.color && colorProduct.color.color_cd}
                </div>
                <div className="color-name">
                  {i18n.language === 'en'
                    ? colorProduct.color && colorProduct.color.eng_name
                    : colorProduct.color && colorProduct.color.display_name}{' '}
                </div>
                <div className="lens-color">
                  {i18n.language === 'en' ? colorProduct.lens_color_eng : colorProduct.lens_color}
                  {!isEmptyObject(
                    colors.find((color) => (i18n.language === 'en' ? color.lens_color_eng : color.lens_color))
                  ) && <>&nbsp;</>}
                </div>
              </div>
            )}
            {isEmptyObject(colorProduct) && (
              <div className="color-info-hide">
                <div>カラーがない際</div>
                {!isEmptyObject(
                  colors.find((color) => (i18n.language === 'en' ? color.lens_color_eng : color.lens_color))
                ) && <div>レンズカラーダミー</div>}
              </div>
            )}
            {product.new_flag === '1' ? (
              <img className="new-label" src={`${IMG_URL}/img/ico/new.png`} />
            ) : (
              <img className="new-label-hide" src={`${IMG_URL}/img/ico/new.png`} />
            )}
            <div className="names">
              <div className="series-name">
                {i18n.language === 'en'
                  ? product.series && product.series.eng_name
                  : product.series && product.series.display_name}
              </div>
              {isEmptyObject(colorProduct) && (
                <div className="model-name">
                  {i18n.language === 'en'
                    ? product.model && product.model.eng_name
                    : product.model && product.model.display_name}
                  {!isEmpty(imageNumber.attribute) && (
                    <div className="model-name-attribute">{imageNumber.attribute}</div>
                  )}
                </div>
              )}
              {!isEmptyObject(colorProduct) && (
                <div className="model-name">
                  {i18n.language === 'en'
                    ? colorProduct && colorProduct.eng_name
                    : colorProduct && colorProduct.display_name}
                  {!isEmpty(imageNumber.attribute) && (
                    <div className="model-name-attribute">{imageNumber.attribute}</div>
                  )}
                </div>
              )}
            </div>
            <div
              className={classNames('price', {
                invisible:
                  i18n.language === 'en' ||
                  isEc ||
                  Boolean(Number((!isEmptyObject(colorProduct) ? colorProduct : product).ec_display_flag)) ||
                  (!isEmptyObject(colorProduct) ? colorProduct : product)?.class2?.id == 27,
              })}
            >
              <div className="price-label">希望小売価格</div>
              <div className="price-value">
                {(!isEmptyObject(colorProduct) ? colorProduct : product).sell_unitprice_w_tax.toLocaleString()}
                円(税込)
              </div>
            </div>
            <div className="images">
              <div className="images-label">COLOR / IMAGE</div>
              <div className="images-slider">
                <Swiper
                  loop={true}
                  slidesPerView={3}
                  watchSlidesProgress={true}
                  onSwiper={(swiper) => this.setState({ imageSlider: swiper })}
                  onTouchEnd={(swiper) => {
                    if (this.state.mainSlider && this.state.mainSlider.realIndex !== swiper.realIndex) {
                      this.state.mainSlider.slideToLoop(swiper.realIndex)
                    }
                  }}
                >
                  {imagesProducts.map((imagesProduct, index) => (
                    <SwiperSlide key={index} className={ClassNames('images-slider-item')}>
                      <ProductImage
                        {...{ [imagesProduct.type]: imagesProduct.product }}
                        imageNo={imagesProduct.image_number.number}
                        onClick={() => this.state.mainSlider.slideToLoop(index)}
                      />
                      {!isEmptyObject(imagesProduct.image_number.name) ? (
                        <div className="image_name">{imagesProduct.image_number.name}</div>
                      ) : (
                        <div className="image_name_hide">image_name</div>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
                <PrevArrow
                  className="swiper-navigation slick-arrow slick-prev"
                  onClick={() => this.state.mainSlider.slidePrev()}
                />
                <NextArrow
                  className="swiper-navigation slick-arrow slick-next"
                  onClick={() => this.state.mainSlider.slideNext()}
                />
              </div>
            </div>
            {product.series !== null && (
              <div className="models">
                <div className="models-label">MODEL</div>
                {/* <div className="models-slider" style={{width: `calc((120px + 6px) * ${Math.min(product.series.models.length, 4)} - 6px)`, margin: 'auto', maxWidth:'344px'}}> */}
                <div
                  className={`models-slider ${
                    product.series.models.length <= 2 ? `items_${product.series.models.length}` : ''
                  }`}
                >
                  <Swiper
                    loop={true}
                    slidesPerView={3}
                    watchSlidesProgress={true}
                    initialSlide={
                      product.series !== null &&
                      product.series.models.findIndex((model) => model.id === product.model.id)
                    }
                    onSwiper={(swiper) => this.setState({ modelSlider: swiper })}
                    onTransitionStart={(swiper) => {
                      onClickSeriesSlider(swiper.realIndex, product.series.models[swiper.realIndex])
                    }}
                  >
                    {product.series &&
                      product.series.models.map((model, index) => (
                        <SwiperSlide
                          key={index}
                          className={ClassNames('models-slider-item')}
                          onClick={() => this.state.modelSlider.slideToLoop(index)}
                        >
                          <ProductImage model={model} imageNo={1} />
                          <div className="model_name">
                            {i18n.language === 'en' ? model.eng_name : model.display_name}
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                  <PrevArrow
                    className="swiper-navigation slick-arrow slick-prev"
                    onClick={() => this.state.modelSlider.slidePrev()}
                  />
                  <NextArrow
                    className="swiper-navigation slick-arrow slick-next"
                    onClick={() => {
                      // slideNextだとモデル数が2つのとき次に進めなくなる
                      this.state.modelSlider.slideToLoop(
                        (this.state.modelSlider.realIndex + 1) % product.series.models.length
                      )
                    }}
                  />
                </div>
              </div>
            )}
            <div className="actions">
              {/* NOTE getLatestCollectionFlag(),isEmptyObject(member)の条件はなくなる可能性あり */}
              <div className="bottonArea">
                {!getLatestCollectionFlag() &&
                  !isEmptyObject(member) &&
                  !isEc &&
                  Number(product.ec_display_flag) != 1 &&
                  i18n.language !== 'en' &&
                  (isEmptyObject(colorProduct) ? (
                    <button onClick={onFlowStockAction} disabled>
                      {t('店舗在庫を確認する')}
                      <br />
                      {t('(カラーを指定してください)')}
                    </button>
                  ) : (
                    <button onClick={onFlowStockAction}>{t('店舗在庫を確認する')}</button>
                  ))}
                {i18n.language !== 'en' && !isEc && Number(product.ec_display_flag) == 1 && (
                  <button onClick={redirectEc}>オンラインで購入する</button>
                )}
                {isEmptyObject(member) && !Boolean(Number(product.ec_display_flag)) && (
                  <button onClick={() => this.linkShopList()}>{t('ショップリスト')}</button>
                )}
                {i18n.language !== 'en' && (
                  <button className={`favorite ${is_favorited && 'favorited'}`} onClick={(e) => onToggleFavorite()}>
                    {is_favorited ? (
                      <>
                        <IoMdHeart />
                        {t('お気に入りに追加済み')}
                      </>
                    ) : (
                      <>
                        <IoMdHeartEmpty />
                        {t('お気に入りに追加する')}
                      </>
                    )}
                  </button>
                )}
              </div>
              {(() => {
                if (isEc && i18n.language !== 'en') {
                  if (!isEmptyObject(colorProduct) && Boolean(Number(colorProduct.ec_display_flag))) {
                    return (
                      <EcCartForm
                        product_code={colorProduct.product_cd}
                        product_price={colorProduct.sell_unitprice_w_tax.toLocaleString()}
                        ec_stocks={ecStocks}
                        lens_flag={colorProduct.ec_lens_display_flag}
                        colorProduct={colorProduct}
                        product={product}
                      />
                    )
                  } else if (isEmptyObject(colorProduct) && Boolean(Number(product.ec_display_flag))) {
                    return (
                      <EcCartForm
                        product_code={itemCode}
                        product_price={product.sell_unitprice_w_tax.toLocaleString()}
                        ec_stocks={ecStocks}
                        lens_flag={product.ec_lens_display_flag}
                        colorProduct={colorProduct}
                        product={product}
                      />
                    )
                  }
                }
              })()}

              {isEc &&
                Boolean(Number(product.ec_display_flag)) &&
                product.class1.web_consept_url &&
                i18n.language !== 'en' && (
                  <div className="concept-section">
                    <a href={product.class1.web_consept_url}>{`${product.class1.web_display_name} CONCEPT・`}</a>
                  </div>
                )}
            </div>
            <div className="detail">
              <div className="detail-label">DETAIL</div>
              <ProductDetail product={product} isEc={isEc} otherTypesSizes={otherTypesSizes} />
            </div>
            <div className="size-link">
              <a href={i18n.language === 'en' ? `${HP_EN_URL}/parts-and-size/` : `${HP_URL}/parts-and-size/`}>
                {t('各種名称・サイズについて')}
              </a>
            </div>
          </div>
        )}
        <div className="back-to-items" onClick={(e) => goBack()}>
          <img src="/images/group_7771.svg" className="arrow" />
          {t('一覧へ')}
        </div>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            {!isEmpty(referencePath) && !isEmpty(referenceName) && (
              <BreadcrumbItem>
                <a onClick={() => link(referencePath)}>{t(referenceName)}</a>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active>{t('商品詳細')}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { color_item_code = null } = props.match.params

  const isEc = !!props.history.location.pathname.match(/\/ec\//)

  const selector = formValueSelector('product_detail')

  const product = state.productDetail.productDetail || {}

  let colors = state.productDetail.colorGroupProducts || []

  let colorProduct =
    color_item_code === null || isEmptyObject(colors)
      ? null
      : colors.find((product) => product.product_cd === color_item_code)

  // Imagesスライダーにするデータをまとめる
  let imagesProducts = []
  if (product.model) {
    imagesProducts = imagesProducts.concat(
      product.model.image_numbers.map((image_number) => ({
        type: 'model',
        product: product.model,
        image_number,
      }))
    )
  }

  colors.forEach((color) => {
    if (isEmptyObject(color.image_numbers)) {
      imagesProducts.push({
        type: 'product',
        product: color,
        image_number: {
          number: 1,
          name: !isEmptyObject(color.color) ? `col. ${color.color.color_cd}` : '',
          attribute: null,
        },
      })
      return
    }

    imagesProducts = imagesProducts.concat(
      color.image_numbers.map((image_number) => ({
        type: 'product',
        product: color,
        image_number: {
          ...image_number,
          name: !isEmpty(image_number.name)
            ? image_number.name
            : !isEmptyObject(color.color)
            ? `col. ${color.color.color_cd}`
            : '',
        },
      }))
    )
  })

  // 商品グループ3に属する商品の画像番号「1」を先にならべて、その後に2以降を商品ごとに並べる
  let first_or_model_type_images = []
  let not_first_or_product_type_images = []
  imagesProducts.forEach((imageProduct) => {
    if (
      (parseInt(imageProduct.image_number.number) === 1 && imageProduct.type === 'product') ||
      imageProduct.type === 'model'
    ) {
      // 商品の画像番号「1」
      first_or_model_type_images.push(imageProduct)
    } else {
      //  商品の画像番号「1」以外
      not_first_or_product_type_images.push(imageProduct)
    }
  })

  imagesProducts = [...first_or_model_type_images, ...not_first_or_product_type_images]

  // モデル画像が無い時は先頭の色が選択された状態にする
  if (!isEmptyObject(imagesProducts) && imagesProducts[0].type === 'product' && isEmptyObject(colorProduct)) {
    colorProduct = imagesProducts[0].product
  }

  const imageNumber = selector(state, 'imageNumber') || {
    number: '1',
    name: '',
  }

  return {
    itemCode: selector(state, 'itemCode'),
    colorItemCode: color_item_code,
    isEc,
    product,
    colors,
    colorProduct,
    favorites: state.favorites.products || [],
    member: state.member.member,
    imageNumber,
    imagesProducts,
    isWeb: state.location.isWeb,
    otherTypesSizes: state.productDetail.otherTypesSizes,
    ecStocks: state.productDetail.ecStocks,
    referencePath: state.location.referencePath || '',
    referenceName: state.location.referenceName || '',
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getItemDetail: (code, isUpdate = false) => {
      if (isUpdate) {
        dispatch(productDetailActions.changeProductDetail({ code }))
      } else {
        dispatch(productDetailActions.getProductDetail({ code }))
      }
    },
    clearItemDetail: () => {
      dispatch(productDetailActions.init())
    },
    set: (key, value) => {
      dispatch(change('product_detail', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    toggleFavorite: (product) => {
      dispatch(
        authActions.shouldAuth(() => {
          dispatch(favoritesActions.toggle(product))
        })
      )
    },
    flowStock: (product) => {
      dispatch(
        authActions.shouldAuth(() => {
          dispatch(reservationAction.flowStock(product.id))
        })
      )
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'product_detail',
    initialValues: {
      itemCode: null,
      imageNumber: null,
    },
  })(withTranslation()(withRouter(Deatil)))
)

const Content = styled.div`
  .slick-slider {
    touch-action: auto;
  }

  .first-image {
    margin: auto;
    margin-bottom: 17px;
    margin-top: 21px;

    .first-image-item {
      margin: auto;
      display: flex;
      align-items: flex-end;
      width: 310px;
      height: 175px;
      object-fit: fill;

      .product-image {
        overflow: hidden;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        padding: 0;
      }
    }
  }

  .detail-content {
    position: relative;
    background-color: #ffffff;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
    padding-bottom: 20px;

    .content-header {
      position: relative;
      padding-top: 20px;

      .labels {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 10px;
        text-align: left;
        z-index: 1;
      }

      .back {
        position: absolute;
        top: 0px;
        right: 0px;
        text-align: center;
        margin: 0;
        padding: 10px;
        z-index: 5;
      }
    }

    .color-info {
      font: normal normal normal 11px/17px Hiragino Sans;
      .color-code {
        display: inline-block;
        margin-right: 3px;
      }
      .color-name {
        display: inline-block;
        margin-right: 3px;
        margin-left: 3px;
      }
    }
    .color-info-hide {
      visibility: hidden;
      font: normal normal normal 11px/17px Hiragino Sans;
    }

    .labels {
      margin-bottom: 10px;

      .label {
        display: inline-block;
        background: #a7a7a7 0% 0% no-repeat padding-box;
        font: normal normal normal 11px/12px Arial;
        color: #ffffff;
        padding: 3px 6px;
      }
    }

    .new-label {
      display: inline-block;
      background: #a7a7a7 0% 0% no-repeat padding-box;
      font: normal normal normal 11px/12px Arial;
      color: #ffffff;
      height: 18px;
    }

    .new-label-hide {
      visibility: hidden;
      font: normal normal normal 11px/12px Arial;
      height: 18px;
      display: inline-block;
    }

    .names {
      .series-name {
        font-size: 13px;
        color: #000000;
      }
      .model-name {
        height: 42px;
        line-height: 42px;
        font-size: 32px;
      }
      .model-name-attribute {
        display: inline;
        margin-left: 1rem;
      }
    }

    .price {
      font: normal normal normal 13px/23px Arial;
      margin-bottom: 10px;
      .price-label {
        display: inline-block;
        margin-right: 6px;
      }
      .price-value {
        display: inline-block;
        margin-left: 6px;
      }
    }
    .price-hide {
      visibility: hidden;
      font: normal normal normal 13px/23px Arial;
      margin-bottom: 15px;
    }

    .slick-arrow {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      line-height: 20px;
      text-align: center;
      vertical-align: middle;
      top: 25px;
      z-index: 10;
      background-color: #ffffff;

      &:before {
        content: none;
      }

      img {
        width: 15px;
      }
    }
    .slick-prev {
      left: -12px;
    }
    .slick-next {
      right: -14px;
    }

    .images {
      width: 344px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 0px;

      .images-label {
        font: normal normal normal 11px/17px Hiragino Sans;
        margin-bottom: 5px;
      }
      .images-slider {
        position: relative;
      }
      .images-slider-item {
        position: relative;
        padding: 0 2px;
        cursor: pointer;
      }

      .product-image {
        width: 104px;
        height: 50px;
        border: solid 1px white;
        padding: 0;
        margin-top: 0px;
        border-radius: 0;
      }
      .image_name {
        width: 104px;
        mergin-top: 5px;
        color: lightgray;
        mergin-top: 5px;
      }
      .image_name_hide {
        visibility: hidden;
        width: 104px;
        mergin-top: 5px;
        mergin-top: 5px;
      }
      .slick-current,
      .swiper-slide-active {
        .product-image {
          border: solid 1px gray;
        }
        .image_name {
          color: gray;
        }
      }
    }

    .models {
      width: 344px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 12px;
      margin-bottom: 0px;

      .models-label {
        font: normal normal normal 11px/17px Hiragino Sans;
        margin-bottom: 5px;
      }
      .models-slider {
        position: relative;

        &.items_2 .slick-list {
          width: calc((104px + 4px) * 2);
        }
      }
      .models-slider-item {
        position: relative;
        padding: 0 2px;
        cursor: pointer;
      }

      .product-image {
        width: 104px;
        height: 50px;
        border: solid 1px white;
        padding: 0;
        margin-top: 0px;
        border-radius: 0;
      }
      .model_name {
        width: 100%;
        mergin-top: 5px;
        color: lightgray;
        mergin-top: 5px;
      }
      .slick-current,
      .swiper-slide-active {
        .product-image {
          border: solid 1px gray;
        }
        .model_name {
          color: gray;
        }
      }
    }

    .actions {
      margin: 40px 0;

      .bottonArea {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button {
        width: 260px;
        height: 76px;
        line-height: 76px;
        font: normal normal normal 13px/23px Hiragino Kaku Gothic Pro;
        border: 1px solid #c1c1b8;
        background: #c1c1b8 0% 0% no-repeat padding-box;
        color: #ffffff;
        margin: 0 20px 20px;

        svg {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-right: 12px;
        }
      }

      .favorite {
        background-color: #ffffff;
        color: #c1c1b8;

        &.favorited {
          background-color: #c1c1b8;
          color: #ffffff;
        }
      }
    }

    .detail {
      margin: 60px 0px 20px;

      .detail-label {
        font: normal normal normal 20px/24px Helvetica Neue;
        margin-bottom: 20px;
      }

      .toggle-tab {
        .toggle-tab-title {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
        }
      }
    }

    .size-link {
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 0 1.25rem;
    }
  }

  .back-to-items {
    position: relative;
    width: 355px;
    height: 80px;
    line-height: 80px;
    background: #ffffff 0% 0% no-repeat padding-box;
    text-align: center;
    margin: 80px auto;
    cursor: pointer;

    img {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
      margin: auto;
    }
  }

  .button {
    cursor: pointer;
  }

  .concept-section {
    margin-top: 40px;
  }

  .concept-section a {
    font-size: small;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 4px;
  }

  ${media.lg`
  .detail-content {
    margin-top: 60px;

    .first-image {
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 17px;
      width: 500px;

      .first-image-item {
        width: 100%;
        height: 410px;
        object-fit:fill;
  
        .product-image {
          width: auto;
          height: auto;
        }
      }
    }

    .content-header {
      .labels {
        padding-top: 30px;
        padding-right: 36px;
      }
      .back {
        padding-top: 30px;
        padding-right: 36px;
      }
    }

    .names {
      .series-name {
        font: normal normal normal 15px/24px Helvetica Neue;
        color: #83827C;
        opacity: 1;
      }
      .model-name {
        height: 40px;
        line-height: 40px;
        font: normal normal normal 32px/43px Helvetica Neue;
      }
    }

    .price {
      margin-bottom: 10px;
      .price-label {
        font-size:  11px;
      }
      .price-value {
        font-size:  13px;
      }
    }
    .price-hide {
      visibility:hidden;
      margin-bottom: 70px;
    }

    .slick-arrow {
    }

    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
    }
    
    .images {
      width: 492px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: auto;
      margin-right: auto;

      .images-slider{
        position: relative
      }

      .images-label {
        font: normal normal normal 11px/17px Hiragino Sans;
        margin-bottom: 5px;
      }
      .image_name {
        font-size: 14px;
        width: 160px;
        mergin-top: 5px;
        color: lightgray;
      }
      .image_name_hide {
        visibility:hidden;
        width: 160px;
        mergin-top: 5px;
        mergin-top: 5px;
      }

      .images-slider-item{
        padding: 0 2px;
        position: relative;
        top: 0px;
      }

      .product-image {
        width: 140px;
        height: 70px;
        border: solid 1px white;
        padding: 10px;
        margin-top: 0px;
      }
    }

    .models {
      width: 492px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      .model_name {
        font-size: 14px;
        width: 160px;
        mergin-top: 5px;
        color: lightgray;
      }

      .models-label {
        margin-bottom: 52px;
        font: normal normal normal 11px/17px Hiragino Sans;
        margin-bottom: 5px;
      }
      .models-slider{
        position: relative;

        &.items_2 .slick-list {
          width: calc((140px + 24px) * 2)
        }
      }
      .models-slider-item{
        padding: 0 2px;
        position: relative;
        top: 0px;
      }

      .product-image {
        width: 140px;
        height: 70px;
        border: solid 1px white;
        padding: 10px;
        margin-top: 0px;
      }
    }

    .actions {

      button {
        font-size: 13px;
        margin: 0 10px 0 10px;

        svg {
          width: 22px;
        }
      }
      .favorite {
        &.favorited {
          margin: 0 10px 0 10px;
        }
      }

    }

    .detail {
      width: 540px;
      margin-top: 61px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;

      .detail-label {
        font-size: 20px;
        margin-bottom: 22px;
      }

      .toggle-tab {
        .toggle-tab-title {
          padding: 24px 15px 24px 8px;
        }
      }
    }

    .size-link {
      width: 540px;
      margin-bottom: 120px;
      margin-left: auto;
      margin-right: auto;
      font-size: 13px;
    }
  }

  .back-to-items {
    font-size: 13px;
    margin-bottom: 160px;
  }
`}

  ${media.md_lg`
  .detail-content{
    .images {
      width: 324px;
    }
    .models {
      width: 324px;
    }

    .slick-prev {
      left: -30px;
    }
    .slick-next {
      right: -30px;
    }
    .image_name {
      font-size: 13px;
    }
    .model_name {
      font-size: 13px;
    }
  }
`}

${media.sm_md`
  .detail-content{
    .images {
      width: 324px;
    }
    .models {
      width: 324px;
    }

    .slick-prev {
      left: -30px;
    }
    .slick-next {
      right: -30px;
    }
    .image_name {
      font-size: 13px;
    }
    .model_name {
      font-size: 13px;
    }
  }
`}

${media.xs_sm`
  .detail-content{
    .images {
      width: 324px;
    }
    .models {
      width: 324px;
    }

    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
    }
    .image_name {
      font-size: 13px;
    }
    .model_name {
      font-size: 13px;
    }
  }
`}
`

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, CardTitle, CardSubtitle, CardText, CardImg } from 'reactstrap'
import { actions as locationActions } from '../../modules/location'
import styled from 'styled-components'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../atomics/Media'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

class Model extends Component {
  render() {
    const { model, className = '', link, isEc, i18n, pageName } = this.props

    const onClick = () => {
      this.props.link({
        pathname: `/items/${isEc || Boolean(Number(model.model_products[0].ec_display_flag)) ? 'ec/' : ''}${
          model.model_products[0].product_cd
        }`,
        state: {
          referencePath: this.props.location.pathname,
          referenceName: pageName,
          referenceScroll: window.scrollY,
        },
      })
    }

    return (
      <Content className={`model_item ${className}`}>
        <CardBody onClick={(e) => onClick()}>
          {model.model_products[0].new_flag === '1' && <img className="new_label" src={`${IMG_URL}/img/ico/new.png`} />}
          <CardSubtitle className="name">
            {i18n.language !== 'en'
              ? model.model_products[0].series.display_name
              : model.model_products[0].series.eng_name}
          </CardSubtitle>
          <CardTitle>{i18n.language !== 'en' ? model.display_name : model.eng_name}</CardTitle>
          <CardImg
            top
            width="100%"
            src={`${IMG_URL}/img/Model/${model.group_cd}_1.jpg`}
            alt={i18n.language !== 'en' ? model.display_name : model.eng_name}
            className="image"
          />
          {i18n.language !== 'en' && (
            <CardText tag="div" className="price">
              {model.model_products[0].sell_unitprice_w_tax.toLocaleString()}円(税込)
            </CardText>
          )}
        </CardBody>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Model)))

const Content = styled(CardBody)`
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ffffff;

  .new_label {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    background: #a7a7a7 0% 0% no-repeat padding-box;
    font: normal normal normal 11px/12px Arial;
    color: #ffffff;
    max-width: 70px;
    max-height: 18px;
  }
  .card-subtitle {
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 2.66px;
    font-weight: 300;
    margin-bottom: 3px;
  }
  .card-title {
    font-size: 26px;
    line-height: 33px;
    font-weight: bold;
    margin-bottom: 11px;
    display: inline-block;
  }

  .size {
    border-radius: 0;
    font-size: 10px;
    display: inline-block;
    float: right;
    display: block;
    margin: 0 0 0 auto;
    padding: 3px 5px;
    border-color: #bababa;
    cursor: pointer;
  }

  .color {
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 13px;
    min-height: 3rem;
  }
  .color-code {
  }
  .color-name {
  }
  .lens-color {
  }
  .name {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 9px;
    text-align: center;
  }

  .display-name {
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
  }

  .price {
    font-size: 13px;
    margin-bottom: 18px;
    text-align: center;
  }
  .price-label {
    padding-right: 1rem;
  }

  ${media.lg`
padding: 0;

.card-body card-subtitle {
    margin-top: 0;
    margin-bottom: 0;
  }
  .card-body {
    padding: 0;
    height: 250px;
    .image {
      object-fit: cover;
      width: 160px;
      margin:0 auto;
      display: block;
    }
  }

  .name {
    font: normal normal normal 20px/24px Helvetica Neue;
    font-size: 15px;
    padding-top: 30px;
    padding-right: 120px;
    padding-bottom: 20px;
  }

  .display-name {
    text-align:center
    margin-top: 0;
    margin-bottom: 0;
  }
  .price {
    text-align:center;
    margin-bottom: 0;
  }
`}
`

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from 'reactstrap'
import { actions as locationActions } from '../../modules/location'
import styled from 'styled-components'
import { media } from '../../components/atomics/Media'
import { reduxForm} from 'redux-form'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

class ProductInfo extends Component {
  render() {
    const {
      product,
      className = '',
      color = true,
      size = false,
      movie = false,
      link,
      t,
      i18n,
    } = this.props

    return (
      <Content className={`product_info ${className}`}>
        <CardSubtitle>{product.series && product.series.display_name}</CardSubtitle>
        <CardTitle>{product.display_name}</CardTitle>
        {movie && product.model && product.model.movie1 && (
          <div>
            <Button className='size' href={product.model.movie1}>Movie</Button> 
          </div>
        )}
        <CardText tag="div" className="price">
          <span className="price-label">希望小売価格</span>
          <span className="price-value">{product.sell_unitprice_w_tax.toLocaleString()}円(税込)</span>
        </CardText>
        <CardText tag="div" className="color">
          {color && (
            <>
              <div className="color-code">col. {product.color && product.color.color_cd}</div>
              <div className="color-name">{product.color && product.color.display_name}</div>
              { i18n.language === 'en' ? 
                (<div className="lens-color">{t(product.lens_color_eng)}</div>) :
                (<div className="lens-color">{product.lens_color}</div>)
              }
            </>
          )}
        </CardText>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(ProductInfo)
  )
)

const Content = styled(CardBody)`
padding-top: 0;
padding-bottom: 0;

.card-subtitle {
  font-size: 17px;
  line-height: 25px;
  font-weight: 300;
  margin-top: 18px;
  color: #000000;
}
.card-title {
  font-size: 26px;
  line-height: 33px;
  font-weight: bold;
  margin-bottom: 11px;
  display: inline-block;}

.size{
  border-radius: 0;
  font-size: 10px;
  display: inline-block;
  float: right;
  display: block;
  margin: 0 0 0 auto;
  padding: 3px 5px;
  border-color:#BABABA;
  cursor:pointer;
}

.color {
  font-size: 13px;
  line-height: 18px;
  min-height: 3rem;
}
.color-code {
}
.color-name {
}
.lens-color {
}

.price {
  font-size: 13px;
  margin-bottom: 18px;
}
.price-label {
  padding-right: 1rem;
}

${media.lg`
  .card-subtitle {
    font-size: 13px;
    color: #83827C;
    padding-top: 60px;
    padding-bottom: 13px;
  }
  .card-title {
    line-height: 33px;
    margin-bottom: 19px;
    display: inline-block;
    font: normal normal normal 40px/48px Helvetica Neue;
  }
  .price {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .color-code {
    font-size: 14px;
  }
  .color-name {
    font-size: 14px;
  }
  .lens-color {
    font-size: 13px;
  }
`}
`

import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Spinner, Button } from 'reactstrap'
import Carousel from '../../components/atomics/Carousel'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import nl2br from 'react-nl2br'
import Model from '../../components/molecules/Model'
import { actions as modelsActions } from '../../modules/models'
import { isEmpty, isEmptyObject } from '../../util/common'
import { media } from '../../components/atomics/Media'
import { IMG_URL, HP_URL, HP_EN_URL } from '../../constants/api-config'
import { actions as locationActions } from '../../modules/location'
import { actions as wpActions } from '../../modules/wp'
import { reduxForm, change, getFormValues } from 'redux-form'
import { LoadingWrapper, Loading } from '../../components/molecules/Loading'
import queryString from 'query-string'
import { WP_URL } from '../../constants/api-config'

class ModelsCatalog extends Component {
  constructor() {
    super()
    this.state = {
      isPc: window.window.innerWidth > 992 ? true : false,
    }
  }
  componentDidMount() {
    this.props.modelWordpress()
    this.loadData()
    window.addEventListener('resize', this.resizeEvent)
  }
  componentDidUpdate() {
    this.loadData()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEvent)
  }

  loadData = () => {
    // 表示変更判定
    const { class2_ids = '' } = this.props.match.params

    // URLパラメータから商品分類２のIDの配列を作成
    const class2_ids_array = class2_ids.split('_').map((class2_id) => parseInt(class2_id))

    const isEc = !!this.props.history.location.pathname.match(/\/ec\//)

    // 戻ってきたら検索しない
    // MEMO: HistoryBackで戻ったら history.action == POP になる。
    //　　　　一覧へで戻ったら isReturnItems == true で戻している。
    if (this.props.isReturnItems) {
      this.props.locationSet('isReturnItems', false)
    }
    if (!isEmptyObject(this.props.searchedModels)) {
      if (this.props.isReturnItems) {
        // スクロール位置を戻す
        // @@router/LOCATION_CHANGE イベントでスクロール位置を変えても、上手く行かない。
        window.scrollTo(0, this.props.referenceScroll)
        return
      } else if (this.props.history.action === 'POP') {
        // 検索時のパラメータがパスと同じなら再検索しない
        if (
          this.props.searchedParams?.is_ec === isEc &&
          !(this.props.searchedParams?.class2_ids || []).find((i) => class2_ids_array.indexOf(i) === -1) &&
          !class2_ids_array.find((i) => (this.props.searchedParams?.class2_ids || []).indexOf(i) === -1)
        ) {
          return
        }
      }
    }

    if (isEc === this.props.isEc && this.props.formValues && class2_ids === this.props.formValues.class2_ids) {
      return
    }

    this.props.setFormValue('class2_ids', class2_ids)
    this.props.set('isEc', isEc) // TODO: urlから判断する

    // 任意クエリパラメータ 商品分類１のID
    const query = queryString.parse(window.location.search)

    this.props.findProductClasses({
      class2_ids: class2_ids_array,
      class1_id: !isEmpty(query) ? parseInt(query.class1_id) : null,
    })
  }

  resizeEvent = () => {
    const width = window.innerWidth
    if (width >= '992') {
      this.setState({ isPc: true })
    } else {
      this.setState({ isPc: false })
    }
    for (const name in this) {
      if (name.indexOf('wp-content-') === 0 && this[name]) {
        this[name].style.height = this[name].contentWindow.document.body.scrollHeight + 'px'
      }
    }
  }

  render() {
    const {
      isEc,
      productClasses,
      selectedClass1,
      searchedModels,
      isSearching,
      hasMore,
      link,
      set,
      searchModelsNext,
      online_image_numbers,
      isWeb,
      online_discription,
      i18n,
      productDetail,
      t,
      searchModels,
      modelWp,
      online_movie_path,
      online_sp_movie_path,
    } = this.props

    if (isEmptyObject(productClasses)) {
      return (
        <LoadingWrapper style={{ background: '#444444' }}>
          <Loading>
            <Spinner size={'sm'} style={{ width: '5rem', height: '5rem', color: '#eeeeee' }} />
          </Loading>
        </LoadingWrapper>
      )
    }

    // トップ画面に遷移
    const onClickHome = () => {
      if (isWeb) {
        if (i18n.language === 'en') {
          window.location.href = HP_EN_URL
        } else {
          window.location.href = HP_URL
        }
      } else {
        link('/')
      }
    }

    const searching = () => {
      return (
        <LoadingWrapper style={{ background: '#fff' }}>
          <Loading>
            <Spinner olor="secondary" size={'sm'} style={{ width: '5rem', height: '5rem' }} />
          </Loading>
        </LoadingWrapper>
      )
    }

    const onClickConcept = () => {
      window.location.href =
        i18n.language === 'en'
          ? selectedClass1.web_consept_url.replace(HP_URL, `${HP_EN_URL}`)
          : selectedClass1.web_consept_url
    }

    const onClickGuide = () => {
      window.location.href =
        i18n.language === 'en' ? `${HP_EN_URL}/online-store/guide/` : `${HP_URL}/online-store/guide/`
    }

    const onClickContact = () => {
      window.location.href = i18n.language === 'en' ? `${HP_EN_URL}/contact/` : `${HP_URL}/contact/`
    }

    const onSelectedClass1 = (class1) => {
      set('selectedClass1', class1)
      searchModels(class1)
    }

    const onAll = () => {
      set('selectedClass1', null)
      searchModels(null)
    }

    let catalogImage = {
      cd: '',
      numbers: [],
    }

    if (isEc) {
    } else if (!isEmptyObject(selectedClass1)) {
      catalogImage = {
        cd: selectedClass1.class1_cd,
        numbers: selectedClass1.web_catalog2_image_numbers || [],
      }
    }

    let pageName = ''
    if (isEc) {
      pageName = 'ONLINE STORE'
    } else if (!isEmptyObject(selectedClass1)) {
      pageName = selectedClass1.web_display_name
    }

    let catalogTab = {
      className: '',
    }

    if (((isEc && online_image_numbers.length) || (!isEc && catalogImage.numbers.length)) >= 2) {
      catalogTab = {
        className: 'class1-tabs',
      }
    } else {
      catalogTab = {
        className: 'class1-tab',
      }
    }

    return (
      <Content>
        <div className="list-sp">
          <Breadcrumb tag="nav" listTag="div" className="breadcrumb">
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            <BreadcrumbItem>{pageName}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        {isEc && this.state.isPc && (
          <div className="catalog-image list-pc">
            {online_image_numbers.length >= 1 && (
              <Carousel
                items={online_image_numbers}
                renderItem={(item) => <img src={`${IMG_URL}/img/Class/online_${item}.png`} />}
                source={online_movie_path ? `${IMG_URL}/${online_movie_path}.mp4` : null}
                isEc={isEc}
                isPc={this.state.isPc}
              ></Carousel>
            )}
          </div>
        )}
        {isEc && !this.state.isPc && (
          <div className="catalog-image list-sp">
            {online_image_numbers.length >= 1 && (
              <Carousel
                items={online_image_numbers}
                renderItem={(item) => (
                  <img
                    src={`${IMG_URL}/img/Class/online_${item}_sp.png`}
                    onError={(e) => {
                      if (e.target.src != `${IMG_URL}/img/Class/online_${item}.png`) {
                        e.target.src = `${IMG_URL}/img/Class/online_${item}.png`
                      }
                    }}
                  />
                )}
                source={online_sp_movie_path ? `${IMG_URL}/${online_sp_movie_path}.mp4` : null}
                isEc={isEc}
                isPc={this.state.isPc}
              ></Carousel>
            )}
          </div>
        )}
        {!isEc && (
          <>
            <div className="catalog-image list-pc">
              {catalogImage.numbers.length >= 1 && (
                <Carousel
                  items={catalogImage.numbers}
                  renderItem={(item) => <img src={`${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}.png`} />}
                ></Carousel>
              )}
            </div>
            <div className="catalog-image list-sp">
              {catalogImage.numbers.length >= 1 && (
                <Carousel
                  items={catalogImage.numbers}
                  renderItem={(item) => (
                    <img
                      src={`${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}_sp.png`}
                      onError={(e) => {
                        if (e.target.src != `${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}.png`) {
                          e.target.src = `${IMG_URL}/img/Class/${catalogImage.cd}_${item.number}.png`
                        }
                      }}
                    />
                  )}
                ></Carousel>
              )}
            </div>
          </>
        )}
        {/* { isEc && !isEmptyObject(productDetail) && !isEmpty(productDetail.class1.web_consept_url) && (
          <div className="concept">
            <a href={productDetail.class1.web_consept_url}>
              {i18n.language === 'en' ? productDetail.class1.web_eng_display_name : productDetail.class1.web_display_name} CONCEPT
            </a>
          </div>
        )}         */}
        <div className="class2-header">
          {productClasses.length > 1 && (
            <Container fluid={true} className={catalogTab.className}>
              <Row>
                <Col className={`${selectedClass1 === null && 'active'}`} onClick={() => onAll()}>
                  ALL
                </Col>

                {productClasses.map((class1) => (
                  <Col
                    key={class1.id}
                    className={`${class1 === selectedClass1 && 'active'}`}
                    onClick={() => onSelectedClass1(class1)}
                  >
                    {class1.web_display_name}
                  </Col>
                ))}
              </Row>
            </Container>
          )}
          {!isEmptyObject(selectedClass1) && !isEmpty(selectedClass1.web_consept_url) && (
            <div className="concept">
              <a onClick={() => onClickConcept()}>
                {i18n.language === 'en' ? selectedClass1.web_eng_display_name : selectedClass1.web_display_name} CONCEPT
              </a>
            </div>
          )}
          {!(!isEmptyObject(selectedClass1) && !isEmpty(selectedClass1.web_consept_url)) && (
            <div className="concept-hide">
              <a onClick={() => onClickConcept()}>CONCEPTがない場合</a>
            </div>
          )}
          {!isEc && selectedClass1 == null && (
            <div className="frame">
              {!isEmptyObject(online_discription) && (
                <div className="frame-name">
                  {i18n.language === 'en' ? online_discription.eng_name : online_discription.key_name}
                </div>
              )}
              {!isEmptyObject(online_discription) && (
                <div className="frame-description">
                  {i18n.language === 'en' ? nl2br(online_discription.note_eng) : nl2br(online_discription.note)}
                </div>
              )}
            </div>
          )}
          {!isEc && selectedClass1 !== null && (
            <div className="frame">
              {!isEmptyObject(selectedClass1) && (
                <div className="frame-name">
                  {i18n.language === 'en' ? selectedClass1.web_eng_display_name : selectedClass1.web_display_name}
                </div>
              )}
              {!isEmptyObject(selectedClass1) && (
                <div className="frame-description">
                  {i18n.language === 'en'
                    ? nl2br(selectedClass1.web_eng_discription)
                    : nl2br(selectedClass1.web_discription)}
                </div>
              )}
            </div>
          )}
          {isEc && selectedClass1 == null && (
            <div className="frame">
              {!isEmptyObject(online_discription) && (
                <div className="frame-name">
                  {i18n.language === 'en' ? online_discription.eng_name : online_discription.key_name}
                </div>
              )}
              {!isEmptyObject(online_discription) && (
                <div className="frame-description">
                  {i18n.language === 'en' ? nl2br(online_discription.note_eng) : nl2br(online_discription.note)}
                </div>
              )}
            </div>
          )}
          {isEc && selectedClass1 !== null && (
            <div className="frame">
              {!isEmptyObject(selectedClass1) && (
                <div className="frame-name">
                  {i18n.language === 'en' ? selectedClass1.web_eng_display_name : selectedClass1.web_display_name}
                </div>
              )}
              {!isEmptyObject(selectedClass1) && (
                <div className="frame-description">
                  {i18n.language === 'en'
                    ? nl2br(selectedClass1.web_eng_discription)
                    : nl2br(selectedClass1.web_discription)}
                </div>
              )}
            </div>
          )}
        </div>
        {isEc &&
          modelWp.map((post) => (
            <div className="hp-section" key={post.id}>
              <iframe
                scrolling="no"
                ref={(ref) => (this[`wp-content-${post.id}`] = ref)}
                onLoad={(e) => {
                  this[`wp-content-${post.id}`].style.height =
                    this[`wp-content-${post.id}`].contentWindow.document.body.scrollHeight + 'px'
                }}
                srcDoc={`<!DOCTYPE html> 
            　<html lang="ja">
                <head>
                  <link rel="stylesheet" id="wp-block-library-css" href="${WP_URL}/wp-includes/css/dist/block-library/style.min.css?ver=5.7.2" media="all">
                  <link rel="stylesheet" id="wp-assets-css" href="${WP_URL}/wp-content/themes/fournines/assets/style.css?ver=5.7.2" media="all">
                  <style type="text/css">
                    .c-iv {opacity: 1}
                    img { height: auto; }
                    img.alignright { display: block; margin: 0 0 0 auto!important;}
                    img.alignleft { display: block; margin: 0 auto 0 0!important;}
                    img.aligncenter { display: block; margin: 0 auto!important;}
                    h1,
                    h2,
                    h3 {
                    line-height: 1.4;
                    }

                    h1 {
                    font-size: 2.44em;
                    margin-top: 0.67em;
                    margin-bottom: 0.67em;
                    }

                    h2 {
                    font-size: 1.95em;
                    margin-top: 0.83em;
                    margin-bottom: 0.83em;
                    }

                    h3 {
                    font-size: 1.56em;
                    margin-top: 1em;
                    margin-bottom: 1em;
                    }

                    h4 {
                    font-size: 1.25em;
                    line-height: 1.5;
                    margin-top: 1.33em;
                    margin-bottom: 1.33em;
                    }

                    h5 {
                    font-size: 1em;
                    margin-top: 1.67em;
                    margin-bottom: 1.67em;
                    }

                    h6 {
                    font-size: 0.8em;
                    margin-top: 2.33em;
                    margin-bottom: 2.33em;
                    }

                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                      color: inherit;
                      display: block;
                      font-weight: 600;
                    }

                    ul,
                    ol {
                    margin-bottom: 28px;
                    padding-left: 1.3em;
                    margin-left: 1.3em;
                    }

                    ul ul,
                    ul ol,
                    ol ul,
                    ol ol {
                    margin-bottom: 0;
                    }

                    ul li,
                    ol li {
                    margin-bottom: initial;
                    }

                    ul {
                    list-style-type: disc;
                    }

                    ol {
                    list-style-type: decimal;
                    }

                    ul ul,
                    ol ul {
                    list-style-type: circle;
                    }
                  </style>
                </head>
                <body>
                  <div class="l-wrapper">
                    <div class="l-wrapper__inner">
                      <div class="l-content" style="padding-top: 0; padding-bottom: 0; margin: 0 auto; max-width: 100%;">
                        <div class="base-ct">
                          ${post.content.rendered.replace(/(<a .*?)(>.*?<\/a>)/g, '$1 target="_parent" $2')}
                        </div>
                      </div>
                    </div>
                  </div>
                </body>
              </html>`
                  .replace(/\r?\n/g, '')
                  .replace(/>\s+</g, '><')}
              />
            </div>
          ))}
        <div className="result">
          {searchedModels.length === 0 && isSearching === false && (
            <div className="not-found">{t('該当の商品はありませんでした。')}</div>
          )}
          <Container fluid={true} className="models">
            <Row xs={2} lg={4}>
              {searchedModels.map((model) => (
                <Col key={model.id}>
                  <Model key={model.id} model={model} isEc={isEc} pageName={pageName} />
                </Col>
              ))}
            </Row>
          </Container>
          {isSearching && searching()}
          {hasMore && (
            <div
              className="more"
              block
              onClick={(e) => {
                e.target.blur()
                searchModelsNext()
              }}
            >
              VIEW MORE
            </div>
          )}
          {isEc && (
            <div className="online-store">
              <p>{t('オンラインストアに関するご利用ガイド、お問い合わせはこちら')}</p>
              <Button onClick={() => onClickGuide()}>{t('ご利用ガイド')}</Button>
              <Button onClick={() => onClickContact()}>{t('お問い合わせ')}</Button>
            </div>
          )}
        </div>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            <BreadcrumbItem>{pageName}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues('modelCatalogs')(state),
    isEc: state.models.isEc,
    productClasses: state.models.productClasses,
    selectedClass1: state.models.selectedClass1,
    searchedModels: state.models.searchedModels || [],
    searchedParams: state.models.searchedParams || {},
    isSearching: state.models.isSearching || false,
    hasMore:
      state.models.searchedMeta.current_page !== 0 &&
      state.models.searchedMeta.current_page < state.models.searchedMeta.last_page,
    online_image_numbers: state.master.online_image_numbers,
    isWeb: state.location.isWeb,
    online_discription: state.master.online_discription,
    productDetail: state.productDetail.productDetail,
    modelWp: state.wp.modelWordpress,
    online_movie_path: state.master.online_movie_path,
    online_sp_movie_path: state.master.online_sp_movie_path,

    isReturnItems: state.location.isReturnItems,
    referenceScroll: state.location.referenceScroll,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    setFormValue: (key, value) => {
      dispatch(change('modelCatalogs', key, value))
    },
    set: (key, value) => {
      dispatch(modelsActions.set(key, value))
    },
    findProductClasses: (class2_ids) => {
      dispatch(modelsActions.findProductClasses(class2_ids))
    },
    searchModels: (productClass) => {
      dispatch(modelsActions.searchModels(productClass))
    },
    searchModelsNext: () => {
      dispatch(modelsActions.searchModelsNext())
    },
    modelWordpress: () => {
      dispatch(wpActions.modelWordpress())
    },
    locationSet: (key, value) => {
      dispatch(locationActions.set(key, value))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'modelCatalogs',
    initialValues: {
      class2_ids: '',
    },
    destroyOnUnmount: false,
  })(withTranslation()(withRouter(ModelsCatalog)))
)

const Content = styled.div`
  .catalog-image {
    img {
      width: 100%;
    }

    .slick-arrow {
      display: none !important;
    }
    .slick-dots {
      bottom: -15px;
    }
  }

  .container-fluid {
    margin-bottom: 130px;
  }

  .frame {
    margin: 60px 0 60px 0;
    .frame-name {
      font-size: 22px;
      display: inline-block;
      _display: inline;
    }
    .frame-description {
      font-size: 13px;
    }
  }

  .concept {
    margin: 20px;
    text-align: right;
    font-size: 14px;
    cursor: pointer;
    cursor: hand;
  }

  .concept:hover {
    text-decoration: underline;
  }

  // .concept a {
  //   text-decoration: underline;
  //   font-family: "EB Garamond";
  // }

  .concept-hide {
    visibility: hidden;
    margin: 20px;
    text-align: right;
    font-size: 14px;
  }

  .sub-title {
    font-size: 11px;
  }

  .class1-tab {
    white-space: nowrap;
    overflow-y: hidden;
    margin: 0;

    .col {
      padding: 10px 10px;
      text-align: center;
      cursor: pointer;
      color: #808080;
      font-size: 18px;

      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
    .col.active {
      width: 500px;
      border-bottom: solid 2px #808080;
      font-weight: bold;
    }
    .row {
      display: flex;
      flex-wrap: nowrap;
      word-break: keep-all;
      white-space: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .class1-tabs {
    white-space: nowrap;
    overflow-y: hidden;
    margin: 0;
    margin-top: 12px;

    .col {
      padding: 10px 10px;
      text-align: center;
      cursor: pointer;
      color: #808080;
      font-size: 18px;

      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
    .col.active {
      width: 500px;
      border-bottom: solid 2px #808080;
      font-weight: bold;
    }
    .row {
      display: flex;
      flex-wrap: nowrap;
      word-break: keep-all;
      white-space: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .models {
    .row {
      justify-content: flex-start;
      padding-bottom: 30px;
    }

    .row:last-child {
      padding-bottom: 0;
    }

    .col {
      border: 1px solid #d6d6d6;
      overflow: hidden;
      border-left-width: 0;
    }
    .col:nth-child(2n) {
      border-right-width: 0;
    }
    .col:nth-child(n + 3) {
      border-top-width: 0;
    }
  }

  .card-body {
    position: relative;
    height: 259px;
    cursor: pointer;
    .image {
      width: calc((100% - 54px));
    }
  }

  .card-subtitle {
    margin: 0;
  }

  .card-title {
    line-height: 23px;
    font-size: 20px;
    font-weight: normal;
    display: block;
    padding-left: 20px;
    margin-bottom: 42px;
  }

  .name {
    padding: 25px 0 0 20px;
    text-align: left;
    font-size: 13px;
    line-height: 15px;
  }

  .price {
    position: absolute;
    width: 100%;
    bottom: 30px;
    margin-bottom: 0;
  }

  button {
    margin: 5px;
    width: 260px;
    height: 76px;
    border: none;
    color: #ffffff;
    background-color: #c1c1b8;
  }

  .online-store {
    margin-bottom: 40px;
    text-align: center;
  }

  .hp-section {
    padding-bottom: 0px;
    padding-bottom: 40px;
    iframe {
      width: 100%;
      overflow: hidden;
    }
  }

  ${media.xs`
    .card-body {
      padding: 0;
      .image {
        margin: 0 auto;
        display: block;
      }
    }
`}

  ${media.sm_md`
    .concept {
      text-align: center;
      margin-top: 40px;
    }
    .concept a {
      padding-bottom: 8px;
      border-bottom: 1px solid #000000;
      text-decoration: unset;
    }

    .concept-hide {
      visibility:hidden;  
      margin-top: 40px;
    }
  `}
  ${media.xs_sm`
    .concept {
      text-align: center;
      margin-top: 40px;
    }
    .concept a {
      padding-bottom: 8px;
      border-bottom: 1px solid #000000;
      text-decoration: unset;
    }

    .concept-hide {
      visibility:hidden;  
      margin-top: 40px;
    }

    .frame {
      margin: 59px 20px 60px 20px;
    }

    .card-subtitle {
      width: calc(100% - 60px);
    }
  `}

  ${media.md_lg`
    .card-body {
      padding: 0;
      .image {
        margin: 0 auto;
        display: block;
     }
    }
    .models .col {
      border: 0;

      flex-basis: calc((100% - 60px) / 3);
      margin-right: 30px;
      margin-bottom: 30px;

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }

    .frame {
      --display: flex;
      margin-top: 30px;
  
      .frame-name {
        margin-right: 50px;
      }
    }
  `}

  ${media.lg`
    .slick-slide {
      img {
        height: 100%;
      }
    }

    .models .col {
      border: 0;

      flex-basis: calc((100% - 120px) / 4);
      margin-right: 40px;
      margin-bottom: 30px;

      &:nth-child(4n) {
        margin-right: 0px;
      }
    }
    .frame {
      --display: flex;
      margin-top: 30px;
  
      .frame-name {
        margin-right: 50px;
      }
    }

    .class1-tab .col{
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.active {
        width: 500px;
      }
    }
    
    .class1-tabs .col{
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.active {
        width: 500px;
      }
    }

    .hp-section {
      iframe {
        width: 100%;
        overflow: hidden;
      }
    }
  `}
`
